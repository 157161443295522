// extracted by mini-css-extract-plugin
export var blocks = "course-page-module--blocks--8408a";
export var careerOpportunities = "course-page-module--career-opportunities--60a15";
export var careerOpportunitiesContent = "course-page-module--career-opportunities-content--9eb94";
export var certifications = "course-page-module--certifications--9f678";
export var certificationsContent = "course-page-module--certifications-content--74eba";
export var courseBenefits = "course-page-module--course-benefits--5b192";
export var courseBenefitsContent = "course-page-module--course-benefits-content--06dfb";
export var courseDescription = "course-page-module--course-description--8d986";
export var courseOutline = "course-page-module--course-outline--41718";
export var courseOutlineContent = "course-page-module--course-outline-content--03f76";
export var courseTitle = "course-page-module--course-title--71e83";
export var detailsContent = "course-page-module--details-content--56691";
export var detailsMarker = "course-page-module--details-marker--8b495";
export var learningMethod = "course-page-module--learning-method--d1e16";
export var learningMethodContent = "course-page-module--learning-method-content--f3912";
export var learningMethodsContainer = "course-page-module--learning-methods-container--dd4db";
export var markerClosed = "course-page-module--marker-closed--9399f";
export var markerOpen = "course-page-module--marker-open--89c85";
export var relatedCourses = "course-page-module--related-courses--01e00";
export var showcase = "course-page-module--showcase--9774f";
export var showcaseImage = "course-page-module--showcaseImage--a0503";
export var showcaseLabel = "course-page-module--showcaseLabel--fdf47";
export var showcaseLabelsContainer = "course-page-module--showcaseLabelsContainer--3e30a";
export var summaryContent = "course-page-module--summary-content--22943";