import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PortableText } from "@portabletext/react";
import { FaCertificate } from "react-icons/fa";
import { FiClock, FiBookOpen, FiCalendar } from "react-icons/fi";
import womanImage from "../../static/images/course-page/woman.png";
import teamImage from "../../static/images/course-page/team.png";

import * as styles from "./course-page.module.scss";
// import Seo from '../components/Seo'

const dateOptions = {
  day: "numeric",
  month: "long",
  year: "numeric",
};

export default function CoursePage({ data, pageContext }) {
  const {
    title,
    // featuredImage,
    courseStartDates,
    _rawDescription,
    accreditation,
    _rawBenefits,
    _rawCareerOpportunities,
    _rawCertifications,
    courseOutline,
  } = data.sanityCourse;

  console.log(data.sanityCourse);

  const learningMethodLabelElements = courseStartDates.map(({ learningMethod }) => {
    return (
      <span
        key={learningMethod.title}
        style={{ backgroundColor: learningMethod.themeColour }}
        className={styles.showcaseLabel}
      >
        {learningMethod.title.replace(" Courses", "").replace(" ", "-")}
      </span>
    );
  });

  const learningMethodElements = courseStartDates.map(
    ({ learningMethod, duration, classTimes, startDate }) => {
      return (
        <article key={learningMethod.title} className={styles.learningMethod}>
          <div className="site-container-2xl">
            <h3>{learningMethod.title.replace(" Courses", "").replace(" ", "-")}</h3>
            <div className={styles.learningMethodContent}>
              <span>
                <FiClock /> {duration}
              </span>
              <span>
                <FiBookOpen /> {classTimes}
              </span>
              <span>
                <FiCalendar /> Start Date:{" "}
                {new Date(startDate).toLocaleDateString("en-ZA", dateOptions)}
              </span>
            </div>
          </div>
        </article>
      );
    }
  );

  const accreditationImageElements = accreditation.map(({ title, logo }) => {
    return logo ? (
      <GatsbyImage key={title} image={getImage(logo.asset)} alt={title} />
    ) : (
      title
    );
  });

  // === Meta Keywords ===
  // const metaKeywords = [...seo?.focus_synonyms ? seo.focus_synonyms : []]

  // if (seo?.focus_keyword) {
  //   metaKeywords.unshift(seo.focus_keyword)
  // }

  return (
    <main>
      {/* Showcase with learning method labels */}
      <section className={styles.showcase}>
        {/* <GatsbyImage
          image={getImage(showcaseImage?.asset || featuredImage?.asset)}
          alt=""
          className={styles.showcaseImage}
        /> */}
        <div className={styles.showcaseLabelsContainer}>
          {learningMethodLabelElements}
        </div>
      </section>

      {/* Course title */}
      <h1 className={styles.courseTitle}>
        <div className="site-container-2xl">{title}</div>
      </h1>

      {/* Course Description Section */}
      <section className={`site-container-2xl ${styles.courseDescription}`}>
        <PortableText value={_rawDescription} />
      </section>

      {/* Learning methods with their: duration, class times and start date */}
      <section className={styles.learningMethodsContainer}>
        {learningMethodElements}
      </section>

      {/* Course benifits with accreditation images */}
      <section className={styles.courseBenefits}>
        <h2>
          <div className="site-container-2xl">
            On completion of this course you will have:
          </div>
        </h2>

        <div className={`site-container-2xl ${styles.courseBenefitsContent}`}>
          <PortableText value={_rawBenefits} />
          {accreditationImageElements}
        </div>
      </section>

      {/* Career Opportunities */}
      <section className={styles.careerOpportunities}>
        <div className="site-container-2xl">
          <img src={womanImage} alt="" />
          <div className={styles.careerOpportunitiesContent}>
            <h2>Career Opportunities</h2>
            <PortableText value={_rawCareerOpportunities} />
          </div>
        </div>
      </section>

      {/* Course Certifications (What You Will Get Section) */}
      <section className={styles.certifications}>
        <div className="site-container-2xl">
          <div className={styles.certificationsContent}>
            <h2>What You Will Get</h2>
            <PortableText value={_rawCertifications} />
          </div>
          <img src={teamImage} alt="" />
        </div>
      </section>

      {/* Course Outline */}
      {courseOutline && (
        <section className={styles.courseOutline}>
          <h2>Course Outline</h2>
          <div className={`site-container-2xl ${styles.courseOutlineContent}`}>
            <PortableText value={courseOutline._rawDescription} />
            {courseOutline.displayType === "blocks" ? (
              <div className={styles.blocks}>
                {courseOutline.blocks.map((block) => (
                  <details key={block.title} open>
                    <summary>
                      <span className={styles.summaryContent}>
                        <FaCertificate />
                        <h3>{block.title}</h3>
                      </span>
                      <div className={styles.detailsMarker}>
                        <span className={styles.markerClosed}>+</span>
                        <span className={styles.markerOpen}>-</span>
                      </div>
                    </summary>
                    <div className={styles.detailsContent}>
                      <PortableText value={block._rawContent} />
                    </div>
                  </details>
                ))}
              </div>
            ) : courseOutline.displayType === "points" ? (
              <>
                <p>
                  You will gain knowledge and understanding in the following key areas:
                </p>
                <ul>
                  {courseOutline.points.map((point) => (
                    <li key={point}>{point}</li>
                  ))}
                </ul>
              </>
            ) : null}
          </div>
        </section>
      )}

      {/* Related Courses */}
      <section className={styles.relatedCourses}>
        <h2>Other courses that may interest you</h2>
      </section>
    </main>
  );
}

export const query = graphql`
  query CoursePageQuery($id: String) {
    sanityCourse(id: { eq: $id }) {
      title
      featuredImage {
        asset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      courseStartDates {
        learningMethod {
          title
          themeColour
        }
        duration
        classTimes
        startDate
      }
      _rawDescription
      accreditation {
        title
        logo {
          asset {
            gatsbyImageData
          }
        }
      }
      _rawBenefits
      _rawCareerOpportunities
      _rawCertifications
      courseOutline {
        _rawDescription
        displayType
        points
        blocks {
          title
          _rawContent
        }
      }
      relatedCourses {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        title
        courseStartDates {
          learningMethod {
            title
            themeColour
          }
        }
        slug {
          current
        }
        studyFields {
          slug {
            current
          }
        }
      }
    }
  }
`;
